import React, { useEffect, useState, Fragment } from 'react';
import './announcement-flags.scss';
import IAnnouncementFlagsProps from '../../models/props/announcement-flags.prop';

export default function AnnouncementFlagsAnnouncementFlags({ isPriceSensitive, isThirdParty }: IAnnouncementFlagsProps) {
    const thirdPartyFlag =  <span title="3rd Party Released announcements" className="flag third-party">3</span>;
    const priceSensitiveFlag = <span title="Material or Prescribed announcements" className="flag price-sensitive">P</span>;
    const noFlag = <span>-</span>

    return (
        <Fragment>
            <div>
                {isPriceSensitive ? priceSensitiveFlag : null}
                {isThirdParty ? thirdPartyFlag : null}
                {!(isThirdParty || isPriceSensitive) ? noFlag : null}
            </div>
        </Fragment>
    );
}