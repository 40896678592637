import React, { useState, useEffect, Fragment } from 'react';
import './announcement-detail.scss';
import { useParams, Link, Redirect } from 'react-router-dom';
import IAnnouncementDetailUrlParams from '../../models/interfaces/announcement-detail-url-params.interface';
import IAnnouncementDetailResult from '../../models/interfaces/announcement-detail-result.interface';
import { getAnnouncementDetails } from '../../services/announcement.service';
import sanitize from 'sanitize-html'
import AnnouncementDownloads from '../announcement-download/announcement-download';
import { routes } from '../../services/route-builder.service';

export default function AnnouncementDetail() {
    let { announcementId }: IAnnouncementDetailUrlParams = useParams();
    const [announcementDetail, setAnnouncementDetail] = useState<IAnnouncementDetailResult>({} as IAnnouncementDetailResult);

    useEffect(() => {
        getAnnouncementDetails(announcementId).then(result => {
            setAnnouncementDetail(result);
        })
    }, [announcementId]);

    const buildPubDate = (item : IAnnouncementDetailResult) => {
        if(!item || !item.pubDate) {
            return null;
        }

        const options = {  day: 'numeric', month: 'numeric', year : 'numeric', hour : 'numeric', minute : 'numeric' };
        return new Date(item.pubDate).toLocaleString('en-NZ', options);
    }

    const replaceNewLines = (text : string) => {
        if(!text)
            return {__html : ''};
        return {__html :  sanitize(text).replace('\n', '<br />') };
    }

    const hasAttachments = ( announcementDetail : IAnnouncementDetailResult) => {
        if (!announcementDetail.attachments) {
            return null;
        }
        return <AnnouncementDownloads {...announcementDetail.attachments}></AnnouncementDownloads>
    }

    // Since announcementDetail is instantiated as an empty object, if it is undefined
    // the service call has thrown an error. Blanket return 404
    if (announcementDetail === undefined) {
        return (
            <Fragment>
                <Redirect to={routes.notFound}></Redirect>
            </Fragment>
        )
    }

    return (
        <div className="page-container">
            <div>
                <div className="header d-block d-md-flex">
                    <span className="mobile-back-button d-block d-md-none">
                        <Link to={routes.announcements}><i className="fa fa-chevron-left"></i> Back</Link>
                    </span>
                    <Link className="back-button d-none d-md-inline" to={routes.announcements}><i className="fa fa-chevron-left"></i> Back</Link>
                    <h2>{announcementDetail.title}</h2>
                </div>
                <h5 className="date">{buildPubDate(announcementDetail)} {announcementDetail.announcementType}</h5>
                <p className="detail-summary" dangerouslySetInnerHTML={replaceNewLines(announcementDetail.summary)}></p>
            </div>
            {hasAttachments(announcementDetail)}
        </div>
    )
}