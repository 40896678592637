import React, { useEffect, useState, Fragment } from 'react';
import './announcement-data-row.scss';
import IAnnouncementDataRowProps from '../../models/props/announcement-data-row.props';
import AnnouncementFlags from '../announcement-flags/announcement-flags';
import { Link } from 'react-router-dom';
import IAnnouncementItem from '../../models/interfaces/announcement-item.interface';
import { routes } from '../../services/route-builder.service';

export default function AnnouncementDataRow({ announcementItem }: IAnnouncementDataRowProps) {
    if (!announcementItem) {
        return null;
    }

    const buildPubDate = (item : IAnnouncementItem) => {
        const options = {  day: 'numeric', month: 'numeric', year : 'numeric', hour : 'numeric', minute : 'numeric' };
        return new Date(item.pubDate).toLocaleString('en-NZ', options);
    }

    return (
        <Fragment>
            <tr key={announcementItem.announcementId}>
                <td>{announcementItem.companyCode}</td>
                <td><Link to={routes.announcementDetail(announcementItem.announcementId)}>{announcementItem.title}</Link></td>
                <td>{buildPubDate(announcementItem)}</td>
                <td>{announcementItem.announcementType}</td>
                <td>
                    <AnnouncementFlags isPriceSensitive={announcementItem.priceSensitive} isThirdParty={announcementItem.thirdParty}></AnnouncementFlags>
                </td>
            </tr>
        </Fragment>
    );
}