import config from "../config/config"
import IAnnouncementListResult from "../models/interfaces/announcement-list-result.interface"
import IAnnouncementDetailResult from "../models/interfaces/announcement-detail-result.interface";

/**
 * Gets all the announcements from the blob endpoint
 */
export async function getAllAnnouncements(): Promise<IAnnouncementListResult> {
    const url = `${config.blobStorageEndpoint}/index.json`;

    return await fetch(url, {cache : 'no-cache'})
        .then(validateResponse)
        .catch(error => {
            // TODO: please enhance error logging/handling
            console.log(error);
        });
}

/**
 * Gets the details of an individual announcement
 * @param announcementId the id of the individual announcement
 */
export async function getAnnouncementDetails(announcementId: string): Promise<IAnnouncementDetailResult> {
    const url = `${config.blobStorageEndpoint}/${announcementId}.json`;

    return await fetch(url)
        .then(validateResponse)
        .catch(error => {
            // TODO: please enhance error logging/handling
            console.log(error);
        })
}

/**
 * Checks to make sure the http response has a success status code
 * @param response the response from the http request
 */
function validateResponse(response: Response): Promise<any> {
    if (response.status >= 200 && response.status <= 299) {
        return response.json();
    } else {
        throw Error(response.statusText);
    }
}

