import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TelemetryService } from "../../services/telemetry.service";

export default function Telemetry() {
  const [initialized, setInitialized] = useState<boolean>();
  const history = useHistory();

  useEffect(() => {
    if(!initialized)
    {
      setInitialized(true);
      TelemetryService.createTelemetryService(history);
    }
  });

  
  return null;
}