import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.scss';
import AnnouncementDetail from './components/announcement-detail/announcement-detail';
import AnnouncementList from './components/announcement-list/announcement-list';
import { getAllAnnouncements } from './services/announcement.service';
import IAnnouncementItem from './models/interfaces/announcement-item.interface';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import { routes } from './services/route-builder.service';
import NotFound from './components/not-found/not-found';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import TermsOfUse from './components/terms-of-use/terms-of-use';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import Telemetry from './components/telemetry/telemetry';

function App() {
  const [announcementList, setAnnouncementList] = useState<IAnnouncementItem[]>([]);

  useEffect(() => {
    getAllAnnouncements().then(result => {
      setAnnouncementList(result.items);
    })
  }, []);

  return (
    <Router >
      <Telemetry />
      <ScrollToTop />
      <Header />
      <div className="main-content">
        <Switch>
          <Route path={routes.announcementDetail(':announcementId')}>
            {/* details route */}
            <AnnouncementDetail></AnnouncementDetail>
          </Route>
          <Route path={routes.announcements} exact={true}>
            {/* base route */}
            <AnnouncementList announcementItems={announcementList}></AnnouncementList>
          </Route>
          <Route path={routes.privacyPolicy}>
            <PrivacyPolicy></PrivacyPolicy>
          </Route>
          <Route path={routes.termsOfUse}>
            <TermsOfUse></TermsOfUse>
          </Route>
          <Route path={routes.notFound}>
            <NotFound></NotFound>
          </Route>
          <Redirect to={routes.notFound}></Redirect>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
