import React, { Fragment } from 'react';
import './announcement-list.scss';
import IAnnouncementItem from '../../models/interfaces/announcement-item.interface';
import AnnouncementDataRow from '../announcement-data-row/announcement-data-row';
import IAnnouncementListProps from '../../models/props/announcement-list.props';

function AnnouncementList({ announcementItems }: IAnnouncementListProps) {

    const mapAnnouncements = (announcements: IAnnouncementItem[]) => {
        return announcements.map(announcement => <AnnouncementDataRow key={announcement.announcementId} announcementItem={announcement}></AnnouncementDataRow>);
    }

    return (
        <Fragment>
            <div className="page-container">
                <h1>Market Announcements</h1>
                <div className="page-alert">
                   This is an official site operated by NZX Limited to provide alternative access to the market announcements published on <a href="https://nzx.com">nzx.com</a>. While announcements.nzx.com does not offer the same data and functionality as <a href="https://nzx.com">nzx.com</a>, it is part of our operational contingency arrangements for the release of, and access to, market announcements that are intended to allow trading to continue in the event that the <a href="https://nzx.com">nzx.com</a> website is offline. 
                    <br /><br />
                    If you have any questions, please contact NZX Product Operations on +64 4 496 2853 or <a href="mailto:productoperations@nzx.com">productoperations@nzx.com</a>
                </div>
                <div className="showing-text text-right">Showing most recent 200 announcements</div>
                <div>
                    <table className="announcement-list">
                        <tbody>
                            <tr>
                                <th className="company-column">
                                    Company
                            </th>
                                <th className="title-column">
                                    Title
                            </th>
                                <th className="date-column">
                                    Date
                            </th>
                                <th className="type-column">
                                    Type
                            </th>
                                <th className="flag-column">
                                    Flag
                            </th>
                            </tr>
                            {mapAnnouncements(announcementItems)}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    );
}

export default AnnouncementList;
