import React from 'react';
import './announcement-download.scss';
import IAnnouncementAttachement from '../../models/interfaces/announcement-attachement.interface';

function AnnouncementDownloads(props : IAnnouncementAttachement[]) {

    return (
        <div className="downloads-box">
            <h3>Downloads</h3>
            <hr/>
            {Object.values(props).map((item) => {
                return <a className="attachment-link" key={item.id} href={item.hotLink} target="blank" rel="noopener noreferrer"><i className="fa fa-file-pdf-o"></i> {item.title}<br/></a>
            })}
        </div>
    )
}

export default AnnouncementDownloads;
