import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../services/route-builder.service';

function Footer() {
    return (
      <div className="site-footer">
        <div className="footer-container">
          <div className="footer-main">
            <div className="row">
              <div className="col-md-8 col-12 text-md-left text-center">
                <span className="footer-copyright">© Copyright 2020 NZX Limited</span>
                <Link className="footer-link ml-md-3 ml-0 text-center d-block d-md-inline" to={routes.termsOfUse}>Website Terms of Use</Link>
                <Link className="footer-link ml-md-3 ml-0 text-center d-block d-md-inline" to={routes.privacyPolicy}>Privacy Policy</Link>               
                <Link className="footer-link ml-md-3 ml-0 text-center d-block d-md-inline" to={routes.announcements}>Announcements</Link>
              </div>

              <ul className="social-list col-md-4 col-12 text-md-right text-center">
                <li><a href="https://www.linkedin.com/company/75896" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin-square"></i></a></li>
                <li><a href="https://www.facebook.com/NZXLimited/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a></li>
                <li><a href="https://twitter.com/NZXGroup" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCZ6mjQForwG_IrR64NZAkOw" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube-square"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Footer;