import React from 'react';
import './header.scss';
import nzxLogo from './../../nzx-logo-2019-white-txt.svg';

function Header() {


    return (
      <div className="site-header">
        <div className="header-container text-md-left text-center">
          <img src={nzxLogo} alt="NZX" className="nzx-logo" />
        </div>
      </div>
    );
}

export default Header;