import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import config from '../config/config'

export class TelemetryService {
    static createTelemetryService(history : any) {
        let reactPlugin = new ReactPlugin();
        let appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: config.applicationInsightsKey,
                extensions: [reactPlugin],
                extensionConfig: {
                  [reactPlugin.identifier]: { history: history }
                }
            }
        });
        return appInsights.loadAppInsights();
    };
};     