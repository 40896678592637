import React from 'react';
import './not-found.scss';

export default function NotFound() {
    return (
        <div className="centered-message">
            <h1>The page you are looking for could not be found.</h1>
        </div>
    );
}
